<template>
  <div class="function-room-container">
    <!-- 顶部按钮 -->
    <el-row>
      <el-button
        v-throttle
        @click="
          () => {
            this.$router.back()
          }
        "
        >返回</el-button
      >
      <el-button
        v-throttle
        type="primary"
        @click="handleAddFunctionRoomBtnClick"
        >添加功能室</el-button
      >
    </el-row>
    <!-- 查询区域 -->
    <el-row style="margin-top: 20px">
      <el-input
        v-model="functionRoomCondition"
        placeholder="请输入功能室名称或编码"
        style="width: 250px"
        @blur="handleQueryFunctionRoomTableData"
      >
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-input>
    </el-row>

    <!-- 数据表格区域 -->
    <el-row style="margin-top: 20px">
      <el-table
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{
          'text-align': 'center',
        }"
        :data="functionRoomData"
      >
        <el-table-column prop="name" label="功能室名称" center>
        </el-table-column>
        <el-table-column prop="code" label="功能室编码" center>
        </el-table-column>
        <el-table-column prop="address" label="地址" center> </el-table-column>
        <el-table-column prop="status" label="是否启用" center>
          <template slot-scope="scoped">
            <span>{{ scoped.row.status === 1 ? '启用' : '停用' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200px">
          <template slot-scope="scoped">
            <!-- 停用状态 -->
            <div
              v-if="scoped.row.status !== 1"
              style="display: flex; margin-left: 35px"
            >
              <div
                style="
                  margin-right: 10px;
                  cursor: pointer;
                  color: green;
                  text-decoration: underline;
                "
                @click="handleEditFunctionRoomBtnClick(scoped.row)"
              >
                编辑
              </div>
              <div
                style="
                  margin-right: 10px;
                  cursor: pointer;
                  color: rgb(64, 158, 255);
                  text-decoration: underline;
                "
                @click="
                  handleOpenChangeFunctionRoomDialog(
                    '启用',
                    scoped.row.sysOrgExamPlaceRoomFunctionId,
                    scoped.row.code
                  )
                "
              >
                启用
              </div>
              <div
                @click="
                  handleOpenChangeFunctionRoomDialog(
                    '删除',
                    scoped.row.sysOrgExamPlaceRoomFunctionId,
                    scoped.row.code
                  )
                "
                style="cursor: pointer; color: red; text-decoration: underline"
              >
                删除
              </div>
            </div>
            <!-- 启动状态 -->
            <div v-else style="display: flex; margin-left: 35px">
              <div
                style="
                  margin-right: 10px;
                  cursor: pointer;
                  color: green;
                  text-decoration: underline;
                "
                @click="handleEditFunctionRoomBtnClick(scoped.row)"
              >
                编辑
              </div>
              <div
                style="
                  margin-right: 10px;
                  cursor: pointer;
                  color: rgb(64, 158, 255);
                  text-decoration: underline;
                "
                @click="
                  handleOpenChangeFunctionRoomDialog(
                    '停用',
                    scoped.row.sysOrgExamPlaceRoomFunctionId,
                    scoped.row.code
                  )
                "
              >
                停用
              </div>
              <div
                @click="
                  handleOpenChangeFunctionRoomDialog(
                    '删除',
                    scoped.row.sysOrgExamPlaceRoomFunctionId,
                    scoped.row.code
                  )
                "
                style="cursor: pointer; color: red; text-decoration: underline"
              >
                删除
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <!-- 分页组件 -->
    <el-row style="margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChangeFunctionRoomPageInfo"
        @current-change="handleCurrentChangeFunctionRoomPageInfo"
        :current-page="functionRoomPageInfo.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="functionRoomPageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="functionRoomPageInfo.pageTotal"
      ></el-pagination>
    </el-row>
    <el-dialog
      :title="addOrEditFunctionRoom === 1 ? '新增功能室' : '编辑功能室'"
      :visible.sync="addOrEditFunctionRoomDialogVisible"
      width="750px"
      top="10%"
    >
      <div style="width: 600px; margin: auto">
        <el-form
          class="addOrEditFunctionRoomForm"
          :model="addOrEditFunctionRoomForm"
          ref="addOrEditFunctionRoomForm"
          :rules="rules"
          label-width="100px"
        >
          <el-form-item prop="name" label="功能室名称">
            <el-input
              v-model="addOrEditFunctionRoomForm.name"
              placeholder="请输入"
              style="width: 400px"
            />
          </el-form-item>
          <el-form-item prop="code" label="功能室编号">
            <el-input
              v-model="addOrEditFunctionRoomForm.code"
              placeholder="请输入"
              style="width: 400px"
            />
          </el-form-item>
          <el-form-item prop="mainMediaUrl" label="前摄像头主流">
            <el-input
              v-model="addOrEditFunctionRoomForm.mainMediaUrl"
              placeholder="请输入"
              style="width: 400px; margin-right: 10px"
            />
            <el-button
              @click="
                handlePreviewMediaBtnClick(
                  1,
                  addOrEditFunctionRoomForm.mainMediaUrl,
                  addOrEditFunctionRoomForm.code
                )
              "
              >预览</el-button
            >
          </el-form-item>
          <el-form-item prop="assistMediaUrl" label="前摄像头辅流">
            <el-input
              v-model="addOrEditFunctionRoomForm.assistMediaUrl"
              placeholder="请输入"
              style="width: 400px; margin-right: 10px"
            />
            <el-button
              @click="
                handlePreviewMediaBtnClick(
                  2,
                  addOrEditFunctionRoomForm.assistMediaUrl,
                  addOrEditFunctionRoomForm.code
                )
              "
              >预览</el-button
            >
          </el-form-item>
          <el-form-item prop="mainMediaUrl2" label="后摄像头主流">
            <el-input
              v-model="addOrEditFunctionRoomForm.mainMediaUrl2"
              placeholder="请输入"
              style="width: 400px; margin-right: 10px"
            />
            <el-button 
              @click="
                handlePreviewMediaBtnClick(
                  3,
                  addOrEditFunctionRoomForm.mainMediaUrl2,
                  addOrEditFunctionRoomForm.code
                )
              "
            >预览</el-button>
          </el-form-item>
          <el-form-item prop="assistMediaUrl2" label="后摄像头辅流">
            <el-input
              v-model="addOrEditFunctionRoomForm.assistMediaUrl2"
              placeholder="请输入"
              style="width: 400px; margin-right: 10px"
            />
            <el-button 
              @click="
                handlePreviewMediaBtnClick(
                  4,
                  addOrEditFunctionRoomForm.assistMediaUrl2,
                  addOrEditFunctionRoomForm.code
                )
              "
            >预览</el-button>
          </el-form-item>
          <el-form-item prop="address" label="地址">
            <el-input
              v-model="addOrEditFunctionRoomForm.address"
              placeholder="请输入"
              style="width: 400px"
            />
          </el-form-item>
        </el-form>
        <div style="text-align: center">
          <el-button @click="handleCancelEditFunctionRoomBtnClick"
            >取消</el-button
          >
          <el-button type="primary" @click="handleSaveEditFunctionRoomBtnClick"
            >保存</el-button
          >
        </div>
      </div>
    </el-dialog>
    <!-- 预览弹框 -->
    <el-dialog
      title="预览"
      :visible.sync="previewDialogVisible"
      width="1300px"
      top="3%"
      :before-close="handleClose"
    >
      <div
        style="
          position: relative;
          width: 100%;
          height: 80%;
          margin: 0;
          padding: 0;
          padding-bottom: 56.25%;
        "
      >
        <flv-com ref="flvRef" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import mpegtsCom from '@/components/mpegtsVideoCom.vue'
import {
  getFunctionRoomListApi,
  deleteFunctionRoomApi,
  getFunctionRoomDetailApi,
  addFunctionRoomApi,
  updateFunctionRoomApi,
  getProxyStreamApi,
} from '@/api/mechanism/examArea.js'
export default {
  name: 'functionRoom',
  components: { flvCom: mpegtsCom },
  data() {
    const checkFunctionRoomCode = (rule, value, callback) => {
      let reg = /[^a-z0-9A-Z]/g
      if (reg.test(this.addOrEditFunctionRoomForm.code)) {
        callback(new Error('编号只能是数字或字母'))
      } else {
        callback()
      }
    }
    return {
      functionRoomCondition: '',
      addOrEditFunctionRoom: 1, // 1 新增 0 编辑
      functionRoomData: [],
      functionRoomPageInfo: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      sysOrgExamPlaceId: '',
      sysOrgExamPlaceRoomFunctionId: '',
      addOrEditFunctionRoomForm: {
        name: '',
        code: '',
        mainMediaUrl: '',
        assistMediaUrl: '',
        mainMediaUrl2: '',
        assistMediaUrl2: '',
        address: '',
      },
      addOrEditFunctionRoomDialogVisible: false,
      rules: {
        name: [
          { required: true, message: '请输入功能室名称', trigger: 'blur' },
        ],
        code: [
          { required: true, message: '请输入功能室编号', trigger: 'blur' },
          { validator: checkFunctionRoomCode, trigger: 'blur' },
        ],
      },
      previewDialogVisible: false,
      videoPath: '',
    }
  },
  created() {
    let examPlaceInfo = JSON.parse(this.$route.query.examPlaceInfo)
    this.sysOrgExamPlaceId = examPlaceInfo.sysOrgExamPlaceId
    this.fetchFunctionRoomInfo()
  },
  methods: {
    // 获取功能室信息
    fetchFunctionRoomInfo() {
      const ScreenParam = new FormData()
      ScreenParam.append('sysOrgExamPlaceId', this.sysOrgExamPlaceId)
      ScreenParam.append('query', this.functionRoomCondition)
      // ScreenParam.append("query", this.sysOrgExamPlaceId);
      getFunctionRoomListApi(
        this.functionRoomPageInfo.pageIndex,
        this.functionRoomPageInfo.pageSize,
        ScreenParam
      ).then((res) => {
        if (res.success) {
          this.functionRoomData = res.data
          this.functionRoomPageInfo.pageTotal = res.total
        } else {
          this.$message.error(res.msg)
          return
        }
      })
    },
    // 添加功能室按钮
    handleAddFunctionRoomBtnClick() {
      this.addOrEditFunctionRoom = 1
      this.addOrEditFunctionRoomForm = {
        name: '',
        code: '',
        mainMediaUrl: '',
        assistMediaUrl: '',
        mainMediaUrl2: '',
        assistMediaUrl2: '',
      }
      this.addOrEditFunctionRoomDialogVisible = true
    },
    // 编辑功能室按钮
    handleEditFunctionRoomBtnClick(row) {
      getFunctionRoomDetailApi(row.sysOrgExamPlaceRoomFunctionId).then(
        (res) => {
          if (res.success) {
            const { data } = res
            this.addOrEditFunctionRoom = 0
            this.addOrEditFunctionRoomForm = {
              name: data.name,
              code: data.code,
              mainMediaUrl: data.mainMediaUrl,
              assistMediaUrl: data.assistMediaUrl,
              mainMediaUrl2: data.mainMediaUrl2,
              assistMediaUrl2: data.assistMediaUrl2,
            }
            this.sysOrgExamPlaceRoomFunctionId =
              data.sysOrgExamPlaceRoomFunctionId
            this.addOrEditFunctionRoomDialogVisible = true
          } else {
            this.$message.error(res.msg)
            return
          }
        }
      )
    },
    // 新增或编辑保存按钮
    handleSaveEditFunctionRoomBtnClick() {
      this.$refs.addOrEditFunctionRoomForm.validate((valid) => {
        if (valid) {
          if (this.addOrEditFunctionRoom === 1) {
            // 新增
            let addParams = {
              ...this.addOrEditFunctionRoomForm,
              sysOrgExamPlaceId: this.sysOrgExamPlaceId,
            }
            addFunctionRoomApi(addParams).then((res) => {
              if (res.success) {
                this.$message.success('添加成功')
                this.fetchFunctionRoomInfo()
                this.addOrEditFunctionRoomDialogVisible = false
              } else {
                this.$message.error(res.msg)
                return
              }
            })
          } else {
            // 编辑
            let editParams = {
              ...this.addOrEditFunctionRoomForm,
              sysOrgExamPlaceId: this.sysOrgExamPlaceId,
              sysOrgExamPlaceRoomFunctionId: this.sysOrgExamPlaceRoomFunctionId,
            }
            updateFunctionRoomApi(editParams).then((res) => {
              if (res.success) {
                this.$message.success('编辑成功')
                this.fetchFunctionRoomInfo()
                this.addOrEditFunctionRoomDialogVisible = false
              } else {
                this.$message.error(res.msg)
                return
              }
            })
          }
        }
      })
    },
    // 取消按钮
    handleCancelEditFunctionRoomBtnClick() {
      this.addOrEditFunctionRoomDialogVisible = false
    },
    // 启用\禁用\删除--功能室
    handleOpenChangeFunctionRoomDialog(val, id, code) {
      this.$confirm(`确定${val}该功能室吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          if (val === '删除') {
            // 进行删除操作
            let idList = [id]
            deleteFunctionRoomApi(idList).then((res) => {
              if (res.success) {
                this.fetchFunctionRoomInfo()
                this.$message({
                  type: 'success',
                  message: `${val}成功!`,
                })
              } else {
                this.$message.error(res.msg)
                return
              }
            })
          } else {
            let params = {
              sysOrgExamPlaceRoomFunctionId: id,
              sysOrgExamPlaceId: this.sysOrgExamPlaceId,
              status: val === '启用' ? 1 : 0,
              code,
            }
            // 进行编辑操作
            updateFunctionRoomApi(params).then((res) => {
              if (res.success) {
                this.$message({
                  type: 'success',
                  message: `${val}成功!`,
                })
                this.fetchFunctionRoomInfo()
              } else {
                this.$message.error(res.msg)
                return
              }
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: `已取消${val}`,
          })
        })
    },
    // 预览按钮点击
    async handlePreviewMediaBtnClick(cameraLocationType, streamUrl, code) {
      // this.previewDialogVisible = true
      // if (val === 1) {
      //   this.videoPath = this.addOrEditFunctionRoomForm.mainMediaUrl
      // } else if (val === 2) {
      //   this.videoPath = this.addOrEditFunctionRoomForm.assistMediaUrl
      // } else if (val === 3) {
      //   this.videoPath = this.addOrEditFunctionRoomForm.mainMediaUrl2
      // } else {
      //   this.videoPath = this.addOrEditFunctionRoomForm.assistMediaUrl2
      // }
      if (!streamUrl) return this.$message.info('流地址不能为空')
      let data = {
        cameraLocationType,
        code,
        streamUrl,
      }
      this.videoPath = await this.getProxyStreamApiFunc(data)
      if (!this.videoPath) return
      this.videoPath = this.$mediaHttpAddress(this.videoPath)
      this.previewDialogVisible = true
      this.$nextTick(() => {
        this.$refs.flvRef.url = this.videoPath
        this.$refs.flvRef.initPlayer()
      })
    },
    // 查询按钮
    handleQueryFunctionRoomTableData() {
      this.functionRoomPageInfo.pageIndex = 1
      this.functionRoomPageInfo.pageSize = 10
      this.fetchFunctionRoomInfo()
    },
    // 重置按钮
    handleResetFunctionRoomTableData() {
      this.functionRoomCondition = ''
      this.functionRoomPageInfo.pageIndex = 1
      this.functionRoomPageInfo.pageSize = 10
      this.fetchFunctionRoomInfo()
    },
    handleCurrentChangeFunctionRoomPageInfo(val) {
      this.functionRoomPageInfo.pageIndex = val
      this.fetchFunctionRoomInfo()
    },
    handleSizeChangeFunctionRoomPageInfo(val) {
      this.functionRoomPageInfo.pageIndex = 1
      this.functionRoomPageInfo.pageSize = val
      this.fetchFunctionRoomInfo()
    },
    // 获取【考场、功能室】代理流地址
    async getProxyStreamApiFunc(data) {
      try {
        const res = await getProxyStreamApi(data)
        if (res.success) {
          return res.data
        } else {
          this.$message.warning(res.msg)
        }
      } catch (error) {}
    },
    handleClose(done) {
      this.$refs.flvRef.destroyPlayer()
      done()
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .addOrEditFunctionRoomForm {
  .el-form-item__error {
    margin-left: 100px;
  }
}
</style>
